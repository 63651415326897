import React, { useState } from "react"
import { Box, Container, Flex, Heading, Text, Grid } from "@theme-ui/components"

// Begin swiper
import SwiperCore, { Pagination, Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import { InboundLink } from "../link"
import { getPagePath } from "../../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { ChevronLeft, ChevronRight } from "react-feather"
import BlockParallax from "../blockParallax"

SwiperCore.use([Navigation, Mousewheel, Pagination, A11y])
// End swiper

const PageSquareCarousel = ({ title, pages }) => {
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)
  return (
    <Box sx={{ height: "100%",  position: "relative" , zIndex:1}}>
      <Container>
        <Grid columns={[1, 2, 4]}>
          {pages.map(page => (
            <PageThumb page={page} />
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

const PageThumb = ({ page }) => (
  <InboundLink
    to={getPagePath(page, page.locale)}
    sx={{
      textDecoration: "none",
      height: "100%",
      display: "block",
      position: "relative",
    }}
  >
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: ["100%"],
        width: "100%",
        zIndex: -1,
        backgroundColor: !page.banner && "dark",
        ".gatsby-image-wrapper": {
          height: ["100%"],
          width: "100%",
          img: {
            objectPosition: "60% 50%",
          },
        },
        overflow: "hidden",
      }}
    >
      {page.banner && (
        <BlockParallax
          style={{ width: "100%", height: "115%" }}
          translateY={[-20, 0]}
        >
          <GatsbyImage image={page.banner.gatsbyImageData} alt={page.banner.gatsbyImageData} />
        </BlockParallax>
      )}
    </Box>
    <Flex
      sx={{
        height: ["100%"],
        p: [4],
        alignItems: "center",
      }}
    >
      <Box>
        <Box sx={{ minHeight: ["100px", "200px"] }}>
          <Heading as="h2" variant="h6" sx={{ color: "light",fontWeight:"bold", mt: 0 }}>
            {page.title}
          </Heading>
        </Box>
      </Box>
    </Flex>
  </InboundLink>
)

export default PageSquareCarousel
