import React, { useEffect, useState } from "react"
import { Box, Text, Heading, Flex, Container, Grid } from "@theme-ui/components"
import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"
import { GatsbyImage } from "gatsby-plugin-image"

const TextAndBodyBulletCollection = ({
  kicker,
  title,
  description,
  image,
  contents,
}) => {
  return (
    <Box sx={{ minHeight: ["100vh","100%"], height:["auto","100%"], position: "relative", py:[5,7] }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: ["100%"],
          width: "100%",
          zIndex: image ? -1 : 0,
          backgroundColor: !image && "dark",
          ".gatsby-image-wrapper": {
            height: ["100%"],
            width: "100%",
          },
          overflow: "hidden",
        }}
      >
        {image && image.gatsbyImageData && (
          <GatsbyImage image={image.gatsbyImageData} alt={image.gatsbyImageData} />
        )}
      </Box>

      <Container sx={{ height: "100%", position: "relative", zIndex: 1, display:"flex", alignItems:"center" }}>
        <Grid
          columns={[1, "1fr 1fr"]}
          gap={[0, 10]}
          sx={{ alignItems: "start" }}
        >
          <Box>
            <Box>
              <Text as="div" variant="sectionTitle">
                <span>{kicker}</span>
              </Text>
            </Box>
            <Box>
              <Heading as="h2" variant="h3" color="light" sx={{ mb: [7], mt:[3,3] }}>
                {title}
              </Heading>
            </Box>
            <Box>
              <Text
                sx={{ color: "light", lineHeight: "1.33" }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Box>
          </Box>
          <Box as="ul" sx={{
            "list-style-type": "unset",
            color:"light"
          }} gap={[0]}>
            {console.log("contents", contents)}
            {contents.map((bullet, index) => (
              <Bullet bullet={bullet} />
            ))}
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

const Bullet = ({ bullet }) => {
  console.log("bullet")

  return (
    <Box
      as="li"
      columns={[".3fr .8fr"]}
      sx={{
        my:[3,5]
      }}
    >
      <Text sx={{ color: "light" }}>{bullet.title}</Text>
    </Box>
  )
}

export default TextAndBodyBulletCollection
