import React from "react"
import { Box, Container, Grid, Heading, Text, Flex } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import {
  Accordion as AccordionWrapper,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemState,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { GatsbyImage } from "gatsby-plugin-image"
import BlockParallax from "../blockParallax"
import { PlusCircle, MinusCircle } from "react-feather"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"

const Accordion = ({ title, items }) => {
  const primary = getColor(themeUiTheme, "primary")
  const light = getColor(themeUiTheme, "light")

  return (
    <Box mb={[3, 5]}>
      <Container>
        <Grid columns={[1, 1, "2fr 3fr"]} gap={[0, 32]}>
          <Box sx={{ display: ["none", "block"] }}></Box>
          <Heading variant="h2">{title}</Heading>
        </Grid>

        <AccordionWrapper
          preExpanded={[0]}
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
        >
          {items.map((item, index) => {
            return (
              <Box
                sx={{
                  backgroundColor: "primary",
                  borderColor: "lightGrey",
                  mb: [4],
                }}
              >
                <AccordionItem key={index} uuid={index} name={index}>
                  <AccordionItemState>
                    {({ expanded }) => (
                      <>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <Box
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              <Container>
                                <Grid
                                  columns={[".1fr .7fr .2fr"]}
                                  sx={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    py: [3],
                                  }}
                                  gap={32}
                                >
                                  <Flex>
                                    <Text
                                      sx={{
                                        fontSize: 5,
                                        color: "light",
                                        mt: 0,
                                      }}
                                    >
                                      0{index + 1}
                                    </Text>
                                  </Flex>
                                  <Flex
                                    sx={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Heading
                                      as="h3"
                                      variant="p"
                                      sx={{
                                        textTransform: "uppercase",
                                        fontSize: [1],
                                        fontWeight: "400",
                                        color: "light",
                                      }}
                                    >
                                      {item.title}
                                    </Heading>
                                  </Flex>
                                  <Flex
                                    sx={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {expanded ? (
                                      <MinusCircle color={light} size={32} />
                                    ) : (
                                      <PlusCircle color={light} size={32} />
                                    )}
                                  </Flex>
                                </Grid>
                              </Container>
                            </Box>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <Container
                            sx={{
                              paddingTop: [0, 0, 0],
                              backgroundColor: "light",
                            }}
                          >
                            <Grid
                              columns={[1, 1, "2fr 3fr"]}
                              gap={32}
                              sx={{
                                paddingTop: [2, 5],
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  top: 0,
                                  left: 0,
                                  height: ["100%"],
                                  width: "100%",
                                  ".gatsby-image-wrapper": {
                                    height: ["100%"],
                                    width: "100%",
                                  },
                                  overflow: "hidden",
                                }}
                              >
                                <BlockParallax
                                  style={{ width: "100%", height: "115%" }}
                                  translateY={[-20, 0]}
                                >
                                  <GatsbyImage
                                    image={item.image.gatsbyImageData}
                                    alt={item.image.gatsbyImageData}
                                  />
                                </BlockParallax>
                              </Box>

                              <Box>
                                <Text variant="h4" sx={{ mt: 0 }}>
                                  {item.subtitle}
                                </Text>
                                <RichContentStructuredText text={item.body} />
                              </Box>
                            </Grid>
                          </Container>
                        </AccordionItemPanel>
                      </>
                    )}
                  </AccordionItemState>
                </AccordionItem>
              </Box>
            )
          })}
        </AccordionWrapper>
      </Container>
    </Box>
  )
}

export default Accordion
