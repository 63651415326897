import React from "react"
import { Box, Grid, Heading, Text } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"

const BreakLineGrid = ({ kicker, contents, title, description }) => {
  return (
    <Box>
      <Box sx={{ mt: [6], mb: [8], px: [3, 4] }}>
        <Grid columns={[1, 1, "1fr 1fr"]} gap={32}>
          <Box>
            <Box>
              <Heading as="h2" variant="h3" sx={{ color: "dark" }}>
                {title}
              </Heading>
            </Box>
            <Box as="p" dangerouslySetInnerHTML={{ __html: description }} />
          </Box>
        </Grid>
      </Box>
      <Grid columns={[1, 1, "1fr"]} gap={32} sx={{ px: [3, 4], pb: [3, 4] }}>
        <Box>
          <Heading>{kicker}</Heading>
        </Box>
        <Grid
          columns={[2,3,contents.length - 1]}
          gap={[4]}
          sx={{ gridTemplateRows: "1fr 1fr" }}
        >
          {contents.map((content, index) => (
            <Box
              sx={{
                gridColumn: [
                  "auto",
                  index < Math.floor((contents.length - 1) / 2)
                    ? "auto"
                    : index === Math.floor((contents.length - 1) / 2)
                    ? 3
                    : index > Math.floor((contents.length - 1) / 2)
                    ? index -2
                    : "auto",
                  index < Math.floor((contents.length - 1) / 2)
                    ? "auto"
                    : index === Math.floor((contents.length - 1) / 2)
                    ? 3
                    : index > Math.floor((contents.length - 1) / 2)
                    ? index
                    : "auto",
                ],
                gridRow: [
                  "auto",
                  index <= Math.floor((contents.length - 1) / 2) ? 1 : 2,
                ],
              }}
            >
              <Heading
                as="h3"
                variant="h3"
                sx={{
                  lineHeight: ".542",
                  fontWeight: "400",
                  fontSize: [8, 9],
                  mb: [4],
                }}
              >
                {"0" + (index + 1)}
              </Heading>
              <Text
                as="p"
                sx={{
                  fontSize:[1,3],
                  width: "90%",
                  textTransform: "uppercase",
                  lineHeight: "1",
                  fontWeight: "400",
                }}
              >
                {content.title}
              </Text>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default BreakLineGrid
