import React from "react"
import { Box, Grid, Heading,Text, Flex } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"

const TitleAndBody = ({ kicker, title, body }) => {
  return (
    <Box sx={{mb:[7]}}>
      <Grid columns={[1, 1, "1fr 1fr"]} gap={32}>
        <Box>
          <Box>
            <Text as="div" variant="sectionTitle" >
              <span>{kicker}</span>
            </Text>
          </Box>
          <Box>
            <Heading as="h2" variant="h3" sx={{ color: "dark", my:[5] }}>
              {title}
            </Heading>
          </Box>
          <Box>
            <RichContentStructuredText text={body} />
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}

export default TitleAndBody
