import React from "react"
import { Box, Grid } from "theme-ui"

const LinkedinEmbeds = ({ embeds }) => {
  return (
    <Box sx={{ maxWidth: "787px", marginX: "auto" }}>
      <Grid
        columns={[1]}
        sx={{ gridAutoRows: "minmax(min-content, max-content)" }}
      >
        {embeds.map(embed => (
          <Box
            dangerouslySetInnerHTML={{ __html: embed.code }}
            sx={{
              "> div,> p": {
                display: "flex",
                justifyContent: "center",
                iframe: { width: "100%" },
              },
            }}
          ></Box>
        ))}
      </Grid>
    </Box>
  )
}

export default LinkedinEmbeds
