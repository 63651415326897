import React, { useState } from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"

// Begin swiper
import RichContentStructuredText from "../richContentStructuredText"
import { ChevronLeft, ChevronRight } from "react-feather"

// End swiper

const ItemCarousel = ({ kicker, title, items }) => {
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)

  // console.log(items)
  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Container>
        <Grid sx={{ height: "100%" }}>
          <Box>
            <Box>
              <Text as="div" variant="sectionTitle">
                <span>{kicker}</span>
              </Text>
            </Box>
          </Box>
          <Grid columns={[1, "1fr 1fr"]} sx={{my:8}}>
            {items.map((item, index) => (
              <Item richContent={item} index={index} />
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const Item = ({ richContent, index }) => (
  <Flex
    sx={{
      my: [2],
      flexDirection: "column",
      overflow: "hidden",
    }}
  >
    <Grid columns={["50px .8fr"]}>
      <Box>
        <Heading
          as="p"
          variant="h4"
          sx={{ fontWeight: "400", color: "primary", mt: 0 }}
        >
          0{index + 1}
        </Heading>
      </Box>
      <Box>
        <Heading as="h2" variant="p" sx={{ fontSize:[2], mb:[3],color: "inherit", mt: 0 }}>
          {richContent.title}
        </Heading>
        <RichContentStructuredText text={richContent.body} />
      </Box>
    </Grid>
  </Flex>
)

export default ItemCarousel
