import React, { useState } from "react"
import {
  Box,
  Container,
  Text,
  Heading,
  Grid,
  Input,
  Button,
  Flex,
  Label,
  Link,
} from "@theme-ui/components"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import { Textarea, Checkbox } from "theme-ui"
import axios from "axios"
import { InboundLink, OutboundLink } from "../link"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import LongArrowRight from "../LongArrowRight"
import { i18nContext } from "../../context/i18nContext"

const ContactForm = ({
  title,
  subtitle,
  description,
  contactPage,
  privacyPolicyDescription,
  newsletterDescription,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const dark = getColor(themeUiTheme, "dark")
  const isBrowser = typeof window !== "undefined"

  const [formData, setFormData] = useState({
    fullName: "",
    company: "",
    address: "",
    city: "",
    province: "",
    zipcode: "",
    phone: "",
    email: "",
    message: "",
    urlPagina: isBrowser && window.location.href,
  })

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const sendMail = async event => {
    event.preventDefault()

    const result = await executeRecaptcha("dynamicAction")

    setLoading(true)
    const data = formData

    if (result)
      axios
        .post("/.netlify/functions/sendMail", data)
        .then(function (response) {
          setSuccess(true)
          setLoading(false)

          if (typeof window !== "undefined" && window.dataLayer !== undefined) {
            window.dataLayer = window.dataLayer || []

            window.dataLayer.push({
              event: "formSubmission",
              formType: "Contact",
            })
          }
        })
        .catch(function (error) {
          console.log("error", error)
          setLoading(false)
        })
    else setLoading(false)
  }

  return (
    <Box
      sx={{
        mt: [8],
        pt: [5, 5, 5, 8],
        pb: [3, 3, 3, 3],
        backgroundColor: contactPage ? "light" : "primary",
      }}
    >
      <Container name="contactForm">
        <i18nContext.Consumer>
          {t => (
            <Box>
              <Grid
                columns={["1fr", "1fr", "1fr", ".4fr .6fr"]}
                gap={[1, 1, 1, 6]}
              >
                <Box>
                  <Heading
                    as="h2"
                    variant="h1"
                    sx={{
                      my: [4],
                      color: "light",
                      fontSize: [6],
                      fontWeight: "body",
                      letterSpacing: "-.5px",
                      lineHeight: "1.12",
                      em: {
                        fontStyle: "normal",
                        color: "light",
                      },
                    }}
                  >
                    {title}
                  </Heading>
                  <Heading
                    as="h3"
                    variant="h3"
                    sx={{
                      fontSize: [5, 6],
                      color: "light",
                      fontWeight: 300,
                      my: [2, 4],
                    }}
                  >
                    {subtitle}
                  </Heading>
                  <Text
                    as="div"
                    variant="sectionTitle"
                    sx={{
                      my: [0, 4],
                      color: "light",
                      fontSize: [4],
                      fontWeight: 300,
                      letterSpacing: "-.5px",
                      lineHeight: "1.12",
                      em: {
                        fontStyle: "normal",
                        color: "light",
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </Box>
                <Box as="form" onSubmit={sendMail}>
                  <Box sx={{ px: [0, 0, 0] }}>
                    <Grid
                      sx={{
                        flexWrap: "wrap",
                        flexDirection: "column",
                        pb: [6, 6, 0, 0],
                      }}
                      gap={[0, 0, 0, 0]}
                    >
                      <Box
                        sx={{
                          pb: [3, 3, 3, 3],
                          width: ["100%", "100%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              fullName: e.target.value,
                            }))
                          }
                          name={t.formInputTexts.fullName}
                          placeholder={t.formInputTexts.fullName}
                          variant="inputs.light"
                          required
                        />
                      </Box>
                      <Box
                        sx={{
                          pb: [3, 3, 3, 3],
                          width: ["100%", "100%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              company: e.target.value,
                            }))
                          }
                          name={t.formInputTexts.company}
                          placeholder={t.formInputTexts.company}
                          variant="inputs.light"
                          required
                        />
                      </Box>
                      <Grid columns={[1, 2]}>
                        <Box
                          sx={{
                            pb: [3, 3, 3, 3],
                            width: ["100%", "100%"],
                          }}
                        >
                          <Input
                            onChange={e =>
                              setFormData(prevState => ({
                                ...prevState,
                                address: e.target.value,
                              }))
                            }
                            name={t.formInputTexts.address}
                            placeholder={t.formInputTexts.address}
                            variant="inputs.light"
                            required
                          />
                        </Box>
                        <Box
                          sx={{
                            pb: [3, 3, 3, 3],
                            width: ["100%", "100%"],
                          }}
                        >
                          <Input
                            onChange={e =>
                              setFormData(prevState => ({
                                ...prevState,
                                city: e.target.value,
                              }))
                            }
                            name={t.formInputTexts.city}
                            placeholder={t.formInputTexts.city}
                            variant="inputs.light"
                            required
                          />
                        </Box>
                      </Grid>

                      <Grid columns={[1, "1fr .3fr"]}>
                        <Box
                          sx={{
                            pb: [3, 3, 3, 3],
                            width: ["100%", "100%"],
                          }}
                        >
                          <Input
                            onChange={e =>
                              setFormData(prevState => ({
                                ...prevState,
                                province: e.target.value,
                              }))
                            }
                            name={t.formInputTexts.province}
                            placeholder={t.formInputTexts.province}
                            variant="inputs.light"
                            required
                          />
                        </Box>
                        <Box
                          sx={{
                            pb: [3, 3, 3, 3],
                            width: ["100%", "100%"],
                          }}
                        >
                          <Input
                            onChange={e =>
                              setFormData(prevState => ({
                                ...prevState,
                                zipcode: e.target.value,
                              }))
                            }
                            name={t.formInputTexts.zipCode}
                            placeholder={t.formInputTexts.zipCode}
                            type="text"
                            inputmode="numeric"
                            pattern="^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$"
                            variant="inputs.light"
                            required
                          />
                        </Box>
                      </Grid>

                      <Grid columns={[1, 2]}>
                        <Box
                          sx={{
                            pb: [3, 3, 3, 3],
                            width: ["100%", "100%"],
                          }}
                        >
                          <Input
                            onChange={e =>
                              setFormData(prevState => ({
                                ...prevState,
                                phone: e.target.value,
                              }))
                            }
                            name={t.formInputTexts.phone}
                            placeholder={t.formInputTexts.phone}
                            inputmode="tel"
                            variant="inputs.light"
                            required
                          />
                        </Box>
                        <Box
                          sx={{
                            pb: [3, 3, 3, 3],
                            width: ["100%", "100%"],
                          }}
                        >
                          <Input
                            onChange={e =>
                              setFormData(prevState => ({
                                ...prevState,
                                email: e.target.value,
                              }))
                            }
                            name="email"
                            type="email"
                            placeholder="Email"
                            variant="inputs.light"
                            required
                          />
                        </Box>
                      </Grid>
                      <Textarea
                        sx={{
                          fontFamily: "body",
                          fontSize: [2],
                          resize: "vertical",
                        }}
                        onChange={e =>
                          setFormData(prevState => ({
                            ...prevState,
                            message: e.target.value,
                          }))
                        }
                        name={t.formInputTexts.message}
                        placeholder={t.formInputTexts.message}
                        variant="inputs.light"
                        type="textarea"
                      />
                      <Box sx={{ py: 2 }}>
                        <LabeledCheckbox required={true}>
                          <Box
                            sx={{
                              p: { mt: 2, mb: 0, fontSize: [0] },
                              a: { color: dark },
                            }}
                            dangerouslySetInnerHTML={{
                              __html: privacyPolicyDescription,
                            }}
                          />
                        </LabeledCheckbox>
                        <LabeledCheckbox>
                          <Box
                            sx={{ p: { mt: 0, mb: 0, fontSize: [0] } }}
                            dangerouslySetInnerHTML={{
                              __html: newsletterDescription,
                            }}
                          />
                        </LabeledCheckbox>
                      </Box>

                      {!success ? (
                        <Button
                          type="submit"
                          sx={{ mt: [3, 3, 3, 3] }}
                          variant="buttons.fullEmpty"
                        >
                          <i18nContext.Consumer>
                            {t => (
                              <>{loading ? t.loading : t.sendRequestForm}</>
                            )}
                          </i18nContext.Consumer>
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          sx={{ mt: [3, 3, 3, 3] }}
                          variant="buttons.fullEmpty"
                          disabled
                        >
                          {t.requestSent}
                        </Button>
                      )}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Box>
          )}
        </i18nContext.Consumer>
      </Container>
    </Box>
  )
}

const LabeledCheckbox = ({
  children,
  defaultChecked,
  contactPage,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultChecked)
  return (
    <Label
      sx={{
        display: "flex",
        alignItems: "center",
        fontWeight: checked ? "bold" : "normal",
        color: "light",
        "input:checked~.css-kydphz": {
          color: "light",
        },
        svg: {
          color: "light",
        },
        mt: [3, 3, 3, 3],
      }}
    >
      <Checkbox
        sx={{
          color: "light",
          "input:checked~&": {
            color: "light",
          },
        }}
        checked={checked}
        onChange={() => setChecked(!checked)}
        {...props}
      />
      {children}
    </Label>
  )
}

export default ContactForm
