import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Text, Container, Grid } from "@theme-ui/components"
import LongArrowDown from "../../assets/images/long-arow-down.svg"
import BlockParallax from "../blockParallax"
import { useResponsiveValue, useBreakpointIndex } from "@theme-ui/match-media"

const Image = ({ image }) => {
  const index = useBreakpointIndex()
  console.log(image)
  return (
    <Box className="oneSide" sx={{ pt: [0, 0, 0, 0] }}>
      <Grid columns={[1, ".4fr .6fr"]} sx={{ alignItems: "center" }}>
        <Box sx={{ textAlign: "right", px: [8] }}>
          <LongArrowDown />
        </Box>
        <Box mb={5}>
          <Box
            sx={{
              mb: 2,
              height: ["auto","50vh"],
              maxHeight: ["auto","50vh"],
              overflow: "hidden",
              ".gatsby-image-wrapper": {
                height: "100%",
                width:"100%",
                "img":{
                  height: "auto"
                }
              },
            }}
          >
            <BlockParallax
              style={{ width: "100%", height: "115%" }}
              translateY={[20, -20]}
            >
              {image && (
                <GatsbyImage
                  image={index > 1 ? image.desktop : image.mobile}
                  alt={image.gatsbyImageData}
                />
              )}
            </BlockParallax>
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}

export default Image
