import React from "react"
import { Box, Text, Grid, Heading, Flex, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import BlockParallax from "../blockParallax"

const PageGrid = ({ contents }) => {
  return (
    <Box sx={{ backgroundColor: "light", minHeight: ["50vh"], }}>
      {contents.map((content, index) => (
        <Grid columns={[1, 2]} sx={{ gridAutoFlow: ["dense"], }} gap={[0]}>
          <Flex
            sx={{
              flexDirection: "column",
              textAlign: "left",
              justifyContent: "center",
              gridRow: [1, 1],
              gridColumn: [1, index % 2 === 1 ? 2 : 1],
              pl: [3, 3, 4, 10],
              py:[4]
            }}
          >
            <Box>
              <Text
                as="div"
                variant="sectionTitle"
                sx={{ span: { textTransform: "capitalize" } }}
              >
                <span>{content.kicker}</span>
              </Text>
            </Box>
            <Box>
              <Heading as="h2" variant="h3" sx={{ color: "black", mb: [3,5], mt:[content.kicker ? 3 : 0,4] }}>
                {content.title}
              </Heading>
            </Box>
            <Box sx={{ p: { width: ["100%", "55%"] } }}>
              <RichContentStructuredText text={content.body} />
            </Box>
          </Flex>
          <Box
            sx={{
              gridRow: [2, 1],
            }}
          >
            <Box
              sx={{
                height: ["100%"],
                overflow:"hidden",
                ".gatsby-image-wrapper": {
                  height: "100%",
                  minHeight: ["500px"],
                 
                },
              }}
            >
              <BlockParallax
                style={{ width: "100%", height: "115%" }}
                translateY={[-20, 0]}
              >
                <GatsbyImage image={content.image.gatsbyImageData} alt={content.image.gatsbyImageData} />
              </BlockParallax>
            </Box>
          </Box>
        </Grid>
      ))}
    </Box>
  )
}

export default PageGrid
