import React from "react"
import { graphql } from "gatsby"
import {
  Box,
  Container,
  Grid,
  Text,
  Flex,
  Image as ImageThemeUi,
} from "@theme-ui/components"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import { getPagePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import TitleAndBody from "../components/blocks/titleAndBody"
import PageCarousel from "../components/blocks/pageCarousel"
import PageSquareCarousel from "../components/blocks/pageSquareCarousel"
import ImageGallery from "../components/blocks/imageGallery"
import ImageGalleryLightbox from "../components/blocks/imageGalleryLightbox"
import OrderedList from "../components/blocks/orderedList"
import ItemCarousel from "../components/blocks/itemCarousel"
import Accordion from "../components/blocks/accordion"
import DocumentCollection from "../components/blocks/documentCollection"
import Embed from "../components/blocks/embed"
import PageHero from "./pageHero"
import Image from "../components/blocks/image"
import ImageAndText from "../components/blocks/imageAndText"
import NumbersGroup from "../components/blocks/numbersGroup"
import NumbersCollection from "../components/blocks/numbersCollections"
import ContactForm from "../components/blocks/contactFrom"
import BreakLineGrid from "../components/blocks/breakLineGrid"
import VerticalLines from "../components/verticalLines"
import { useFavicon } from "../hooks/useFavicon"
import PlusItems from "../components/blocks/plusItems"
import TextAndBodyBulletCollection from "../components/blocks/textAndBodyBulletCollection"
import PageGrid from "../components/blocks/pageGrid"
import ContactFooter from "../components/contactFooter"
import LinkedinEmbeds from "../components/blocks/linkedinEmbeds"
import { useMenu } from "../hooks/useMenu"
import SidebarPageDots from "../components/sidebarPageDots"
import { useSocial } from "../hooks/useSocial"
import { Facebook, Instagram, Twitter, Youtube, Linkedin } from "react-feather"
import { MagicLink } from "../utils/magicLink"
import LinkedinWhite from "../images/linkedin-white.png"
import LinkedinBlack from "../images/linkedin-black.png"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"

const LocationsMap = loadable(
  () => import("../components/blocks/locationMap"),
  { ssr: false }
)

const Page = ({ data: { page, menu, social, site } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const treeChildren = []

  const i18nSocial = social.nodes.filter(social => {
    return social.locale === page.locale
  })

  menu.nodes.map(menuItem => {
    menuItem.treeChildren.sort((a, b) => a.position - b.position)
    menuItem.treeChildren.map(menuItem => {
      if (menuItem.treeChildren.length > 0) {
        menuItem.treeChildren.sort((a, b) => a.position - b.position)
      }
    })
  })

  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <VerticalLines />
      <PageHero page={page} image={page.heroImage} />
      {page.content.map(block => (
        <Box
          as="section"
          key={block.id}
          sx={{
            zIndex: block.model.apiKey === "break_line_grid" && 4,
            position: "relative",
          }}
        >
          {block.model.apiKey === "title_and_body" && (
            <>
              {/* <SidebarPageDots page={page} menu={menu.nodes} /> */}
              <Container>
                <TitleAndBody
                  kicker={block.content.kicker}
                  title={block.content.title}
                  body={block.content.body}
                />
              </Container>
            </>
          )}
          {block.model.apiKey === "linkedin_embed" && (
            <Container>
              <LinkedinEmbeds embeds={block.embeds} />
            </Container>
          )}
          {block.model.apiKey === "break_line_grid" && (
            <Container variant="fw" sx={{ pr: [0, 0, 0, 0], px: [0, 4] }}>
              <Grid columns={[1, 1, 1, "calc(50vw - 688px) 1fr"]}>
                <Box></Box>
                <Box sx={{ backgroundColor: "light", mt: [-7], zIndex: 2 }}>
                  <BreakLineGrid
                    kicker={block.kicker}
                    title={block.title}
                    description={block.description}
                    contents={block.contents}
                  />
                </Box>
              </Grid>
            </Container>
          )}
          {block.model.apiKey === "ordered_list" && (
            <OrderedList
              title={block.title}
              subtitle={block.subtitle}
              body={block.body}
            />
          )}
          {block.model.apiKey === "page_carousel" && (
            <PageCarousel title={block.title} pages={block.pages} />
          )}
          {block.model.apiKey === "page_square_carousel" && (
            <PageSquareCarousel title={block.title} pages={block.pages} />
          )}

          {block.model.apiKey === "item_carousel" && (
            <ItemCarousel items={block.items} />
          )}
          {block.model.apiKey === "plus_item" && (
            <PlusItems kicker={block.kicker} items={block.contents} />
          )}
          {block.model.apiKey === "accordion" && (
            <Accordion title={block.title} items={block.items} />
          )}
          {block.model.apiKey === "document_collection" && (
            <DocumentCollection
              title={block.title}
              documents={block.documents}
              showPublicationDate={block.showPublicationDate}
            />
          )}
          {block.model.apiKey === "image" && block.image && (
            <Image image={block.image} />
          )}
          {block.model.apiKey === "locations_map" && (
            <Container sx={{ px: [0, 4], pt: [0, 4] }}>
              <Grid columns={[1, 2]} sx={{ pt: [0, 8] }}>
                <Box sx={{ gridRow: [2, 1] }}>
                  <Text
                    as="div"
                    sx={{
                      h2: {
                        fontSize: [7, 8],
                        fontFamily: "heading",
                        lineHeight: "heading",
                        fontWeight: "heading",
                        color: "dark",
                        mb: 3,
                        mt: 0,
                      },
                      p: {
                        pr: [4],
                      },
                      px: [3, 4],
                    }}
                    dangerouslySetInnerHTML={{
                      __html: block.locations[0].body,
                    }}
                  />
                  <Box>
                    <Flex
                      sx={{
                        p: {
                          pr: [4],
                        },
                        p: [3, 4],
                        my: [0],

                        flexWrap: "wrap",
                        "> div": { mx: [0, 0], mr: [2, 2] },
                      }}
                    >
                      {i18nSocial.map(social => {
                        return (
                          <>
                            {social && social.facebook && (
                              <Box>
                                <MagicLink
                                  target="_blank"
                                  href={social.facebook}
                                >
                                  <Facebook color="#FFF" />
                                </MagicLink>
                              </Box>
                            )}
                            {social && social.twitter && (
                              <Box>
                                <MagicLink
                                  target="_blank"
                                  href={social.twitter}
                                >
                                  <Twitter color="#FFF" />
                                </MagicLink>
                              </Box>
                            )}
                            {social && social.instagram && (
                              <Box>
                                <MagicLink
                                  target="_blank"
                                  href={social.instagram}
                                >
                                  <Instagram color="#FFF" />
                                </MagicLink>
                              </Box>
                            )}
                            {social && social.linkedin && (
                              <Box>
                                <MagicLink
                                  target="_blank"
                                  href={social.linkedin}
                                >
                                  <ImageThemeUi
                                    variant="avatar"
                                    src={LinkedinBlack}
                                  />
                                </MagicLink>
                              </Box>
                            )}
                            {social && social.youtube && (
                              <Box>
                                <MagicLink
                                  target="_blank"
                                  href={social.youtube}
                                >
                                  <Youtube color="#FFF" />
                                </MagicLink>
                              </Box>
                            )}
                          </>
                        )
                      })}
                    </Flex>
                  </Box>
                </Box>
                <Box sx={{ gridRow: [1, 1], iframe: { width: "100%" } }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d160765.51367130797!2d10.172242497055311!3d45.65471861955754!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x62a6455dee24bd7f!2sSom%20Di%20Reboldi%20Dario%20Ed%20Enzo%20Srl!5e0!3m2!1sen!2sit!4v1645626981129!5m2!1sen!2sit"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                  />
                  {/* <LocationsMap locations={block.locations} /> */}
                </Box>
              </Grid>
            </Container>
          )}
          {block.model.apiKey === "contact_form" && (
            <ContactForm
              kicker={block.kicker}
              title={block.title}
              subtitle={block.subtitle}
              privacyPolicyDescription={block.privacyPolicyDescription}
              newsletterDescription={block.newsletterDescription}
            />
          )}
          {block.model.apiKey === "embed" && (
            <Embed
              title={block.title}
              code={block.code}
              fullWidth={block.fullWidth}
            />
          )}
          {block.model.apiKey === "video" && (
            <Embed
              title={block.title}
              code={block.code}
              fullWidth={block.fullWidth}
            />
          )}
          {block.model.apiKey === "image_gallery" && (
            <ImageGallery images={block.images} />
          )}
          {block.model.apiKey === "image_gallery_lightbox" && (
            <ImageGalleryLightbox images={block.images} />
          )}
          {block.model.apiKey === "numbers_collection" && (
            <NumbersCollection
              kicker={block.kicker}
              description={block.description}
              title={block.title}
              image={block.image}
              numbers={block.numbers}
            />
          )}
          {block.model.apiKey === "page_grid" && (
            <PageGrid contents={block.contents} />
          )}

          {block.model.apiKey === "title_and_body_bullet_collection" && (
            <TextAndBodyBulletCollection
              kicker={block.kicker}
              description={block.description}
              title={block.title}
              image={block.image}
              contents={block.contents}
            />
          )}
          {block.model.apiKey === "image_and_text" && (
            <ImageAndText
              kicker={block.content.kicker}
              subtitle={block.content.subtitle}
              title={block.content.title}
              body={block.content.body}
              image={block.image}
            />
          )}
        </Box>
      ))}
      {!page.hideContact && <ContactFooter />}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    social: allDatoCmsSocial {
      nodes {
        id
        locale
        facebook
        linkedin
        instagram
        youtube
        twitter
      }
    }
    page: datoCmsPage(id: { eq: $id }) {
      ...PageDetails
      ...PageTreeParent
      ...AllSlugLocales
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      hideContact
      content {
        ... on DatoCmsNumbersCollection {
          id
          kicker
          title
          shortTitle
          description
          title
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }

          numbers {
            legend
            float
            suffix
            prefix
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndBodyBulletCollection {
          id
          kicker
          title
          shortTitle
          description
          title
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          contents {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsLinkedinEmbed {
          id
          embeds {
            code
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
        ... on DatoCmsEmbed {
          id
          ...EmbedDetails
        }
        ... on DatoCmsVideo {
          id
          code
          model {
            apiKey
          }
        }
        ... on DatoCmsBreakLineGrid {
          id
          title
          description
          kicker
          contents: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPageGrid {
          id
          contents {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndText {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImage {
          id
          image {
            desktop: gatsbyImageData(
              placeholder: NONE
              forceBlurhash: false
              imgixParams: {
                blendColor: "#212C30"
                blendMode: "multiply"
                blendAlpha: 45
              }
            )
            mobile: gatsbyImageData(
              placeholder: NONE
              forceBlurhash: false
              imgixParams: {
                fit: "crop"
                ar: "16:10"
                h: "800"
                blendColor: "#212C30"
                blendMode: "multiply"
                blendAlpha: 30
              }
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsCategory {
          id
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsLocationsMap {
          id
          locations {
            originalId
            name
            body
            coordinates {
              latitude
              longitude
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageGallery {
          id
          ...ImageGallery
        }
        ... on DatoCmsImageGalleryLightbox {
          id
          ...ImageGalleryLightboxDetails
        }
        ... on DatoCmsDocumentCollection {
          id
          title
          showPublicationDate
          documents {
            title
            subtitle
            documents {
              url
              title
              format
            }
            meta {
              firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsAccordion {
          id
          title
          items: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndBody {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsOrderedList {
          id
          title
          subtitle
          body {
            blocks
            links
            value
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPageSquareCarousel {
          id
          title
          pages {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPageCarousel {
          id
          title
          pages {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPlusItem {
          id
          kicker
          contents {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
      }
    }
    menu: allDatoCmsMenu(
      filter: { root: { eq: true }, anchor: { ne: null } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        locale
        root
        anchor
        link {
          ... on DatoCmsInternalLink {
            id
            anchor
            locale
            model {
              apiKey
            }
            link {
              ... on DatoCmsPage {
                ...PageDetails
                ...PageTreeParent
                ...AllSlugLocales
              }
              ... on DatoCmsArticle {
                ...ArticleDetails
                ...ArticleAllSlugLocales
              }
              
            }
          }
          ... on DatoCmsExternalLink {
            id
            anchor
            url
            model {
              apiKey
            }
          }
        }
        treeChildren {
          id
          locale
          root
          anchor
          link {
            ... on DatoCmsInternalLink {
              id
              anchor
              locale
              model {
                apiKey
              }
              link {
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
                ... on DatoCmsArticle {
                  ...ArticleDetails
                  ...ArticleAllSlugLocales
                }
               
              }
            }
            ... on DatoCmsExternalLink {
              id
              anchor
              url
              model {
                apiKey
              }
            }
          }
          treeChildren {
            id
            locale
            root
            anchor
            link {
              ... on DatoCmsInternalLink {
                id
                anchor
                locale
                model {
                  apiKey
                }
                link {
                  ... on DatoCmsPage {
                    ...PageDetails
                    ...PageTreeParent
                    ...AllSlugLocales
                  }
                  ... on DatoCmsArticle {
                    ...ArticleDetails
                    ...ArticleAllSlugLocales
                  }
                  
                }
              }
              ... on DatoCmsExternalLink {
                id
                anchor
                url
                model {
                  apiKey
                }
              }
            }
          }
        }
      }
    }
  }

  fragment RichContent on DatoCmsRichContent {
    title
    kicker
    subtitle
    image {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    body {
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
          link {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
            ... on DatoCmsArticle {
              ...ArticleDetails
              ...ArticleAllSlugLocales
            }
            
          }
        }
      }
      value
    }
    link {
      __typename
      ... on DatoCmsInternalLink {
        id: originalId
        anchor
        locale
        model {
          apiKey
        }
        link {
          ... on DatoCmsPage {
            ...PageDetails
            ...PageTreeParent
            ...AllSlugLocales
          }
          ... on DatoCmsArticle {
            ...ArticleDetails
            ...ArticleAllSlugLocales
          }
        }
      }
    }
    model {
      apiKey
    }
  }

  fragment AllSlugLocales on DatoCmsPage {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment PageDetails on DatoCmsPage {
    id
    locale
    title
    slug
    root
    model {
      apiKey
    }
    heroImage {
      desktop: gatsbyImageData(
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 40
          fit: "crop"
          ar: "16:5"
          h: "600"
        }
      )
      mobile: gatsbyImageData(
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 30
        }
      )
    }
    banner {
      gatsbyImageData(
        height: 1445
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 40
        }
      )
    }
    thumbnailImage {
      gatsbyImageData(
        width: 1920
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 30
        }
      )
    }
  }

  fragment EmbedDetails on DatoCmsEmbed {
    title
    code
    fullWidth
    model {
      apiKey
    }
  }

  fragment PageTreeParent on DatoCmsPage {
    treeParent {
      id
      title
      slug
      root
      locale
      ...AllSlugLocales
      treeParent {
        id
        title
        slug
        root
        locale
        ...AllSlugLocales
      }
    }
  }
`
